import * as React from "react";
import { Header, Icon, Grid, Container, Segment } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const ApplicationsPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="file" />
          <Header.Content>
            Applications Approval
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <Header as="h2"><a href={"/ApplicationsApprovalProcess_PTC220.pdf"}>Applications Approval Process</a></Header>
      </Segment>
    </Container>
  );
};

export default withLayout(ApplicationsPage);